<template>
  <v-card flat>
    <v-card-text>
      <v-form>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field v-model="currentClient.title" color="primary" label="Título" outlined dense />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="currentClient.subtitle" color="primary" label="Subtítulo" outlined dense />
          </v-col>
          <v-col cols="12">
            <label class="text-subtitle-2">Descrição</label>
            <VueEditor v-model="currentClient.description" :editorToolbar="customToolbar" class="background" />
          </v-col>
          <v-col cols="12" md="12">
            <v-combobox
              v-model="currentClient.keywords"
              label="Keywords"
              multiple
              outlined
              dense
              chips
            />
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" md="3" v-for="(color, idx) in currentClient.colors" :key="idx">
            <label class="text-subtitle-2">{{ color.title }}</label>
            <v-color-picker
              v-model="color.value"
              hide-canvas
              mode="hexa"
            ></v-color-picker>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" text @click="$emit('close')"> Cancelar </v-btn>
      <v-btn color="primary" @click="update"> Salvar </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { VueEditor } from "vue2-editor";
export default {
  props: {
    currentClient: {
      type: Object,
      required: true,
    },
  },
  components: {
    VueEditor,
  },
  data: () => ({
    customToolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  }),
  methods: {
      update() {
      this.$emit("update");
      this.$emit("close");
    },
  }
};
</script>

<style>
</style>